@import 'defaults';

html{
    width: 100vw;
    height: 100vh;
    margin: 0;
    padding: 0;

    body{
        margin: 0;
        padding: 0;
        width: 100%;
        height: 100%;
        overflow: hidden;
        background-color: $background-page;
        color: $text-page;
        font-size: 18px;
        display: flex;
        align-items: center;
        justify-content: center;
        box-sizing: border-box;
        font-family: 'Roboto-Regular';

        #root{
            width: 100%;
            height:100%;
            display: flex;
            box-sizing: border-box;
            align-items: center;
            justify-content: center;
        }
    }
}