// Fonts
@font-face {
    font-family: 'Roboto-Regular';
    src: url('../font/Roboto-Regular.woff2') format('woff2');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'NixieOne';
    src: url('../font/NixieOne-Regular.woff2') format('woff2');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'digital-7';
    src: url('../font/digital-7.woff2') format('woff2');
    font-weight: normal;
    font-style: normal;
}

//Colors
$background-page: #000;
$text-page: #FFF;
$background-ray-1: rgb(57, 15, 97);
$background-ray-2: rgb(44, 19, 68);