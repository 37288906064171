@import '../../defaults';

.page-bingo{
    position: relative;
    display: flex;
    width: 100%;
    height: 100%;
    max-height: 100%;
    z-index: 10;
    align-items: flex-start;
    justify-content: flex-start;
    box-sizing: border-box;
    flex-direction: column;
    overflow-y: auto;

    &:before{
        content: '';
        width: 100%;
        height: 40px;
        min-height: 40px;
        display: flex;
        position: relative;
    }

    .bar-top{
        background-color: rgb(50, 16, 88);
        width: 100%;
        height: 40px;
        display: flex;
        justify-content: space-between;
        box-sizing: border-box;
        padding: 10px;
        position: fixed;
        z-index: 100;


        > span{
            width: 33%;
            
            @media screen and (max-width:767px) {

                &:first-child{
                    display: none;
                }
            }
        }

        .clock{
            font-family: 'digital-7';
            font-size: 24px;
            text-align: center;
        }


        .area-buttons{
            display: flex;
            justify-content: flex-end;
            box-sizing: border-box;
            padding: 0 10px;
            > * {
                margin: 0 10px;
                cursor: pointer;
                transition: all 0.5s ease;
                &:hover{
                    transform: scale(1.1);
                    transition: all 0.5s ease;
                }
                
            }

            .button-chat{
                color: rgb(16, 227, 255);
                position: relative;

                &:after{
                    content: '';
                    display: block;
                    background-color: #F00;
                    width: 6px;
                    height: 6px;
                    position: absolute;
                    top: 3px;
                    right: -3px;

                }
            }

            .button-audio{
                color: rgb(255, 217, 0);
            }

            .button-exit{
                color: #F00;
            }
        }
    }

    header.bingo-bolao{
        box-sizing: border-box;
        padding: 20px;
        background-color: rgb(103, 83, 122);
        margin: 10px auto;
        width: 98%;
        justify-content: space-between;
        flex-direction: row;
        display: flex;
        border-radius: 8px;

        .area-logo{

            display: flex;
            flex-direction: column;
            justify-content: center;
            text-align: center;

            .logo-image{
                max-width: 50px;
                width: 100%;
                margin: 5px auto;
            }
        }

        .area-premium, .area-premium-acum{
            display: flex;
            flex-wrap: wrap;

            > div{
                width: 100%;
                text-align: center;

                &:last-child{
                    font-size: 26px;
                    font-weight: bold;
                }
            }
        }
    }

    header.user{
        box-sizing: border-box;
        padding: 20px;
        background-color: rgb(47, 17, 102);
        margin: 10px auto;
        width: 98%;
        justify-content: space-between;
        align-items: center;
        flex-direction: row;
        display: flex;
        border-radius: 8px;

        @media screen and (max-width:767px) {
            flex-direction: column;
        }
        
        .user-data{
            text-align: center;
            font-size: 14px;
            min-width: 100px;
            
            > div{
                margin: 5px auto 10px;
            }
        }

        .user-money{
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;

            > div{
                margin: 10px auto;
                text-align: center;
                display: flex;
                justify-content: space-between;
                align-items: center;
                flex-wrap: wrap;
                width: 30%;

                @media screen and (max-width:767px) {
                    width: 90%;
                }

                > * {
                    width: 100%;
                    display: flex;
                    margin: 5px 0;
                    text-align: center;

                    @media screen and (max-width:767px) {
                        width: unset;
                        display: inline-flex;
                        text-align: left;
                    }
                }
            }
        }

        .user-button-buy{
            text-align: center;
            border-radius: 8px;
            border:1px solid  #FFF;
            box-sizing: border-box;
            padding: 10px;
            cursor: pointer;
            transition: all 0.5s ease;

            @media screen and (max-width:767px) {
                flex-wrap: wrap;
                display: flex;
                align-items: center;
                margin: 15px auto 0;
            }

            > div{
                margin: 5px auto 10px;

                @media screen and (max-width:767px) {
                    margin: 0 10px;
                }
            }

            &:hover{
               svg{
                    transform: scale(1.1);
                    transition: all 0.5s ease;
                }
            }
        }
    }

    .area-cards{
        box-sizing: border-box;
        padding: 20px;
        background-color: rgb(47, 17, 102);
        margin: 10px auto;
        width: 98%;
        justify-content: space-between;
        align-items: center;
        flex-direction: row;
        display: flex;
        border-radius: 8px; 

        > div + div{
            margin-left: 50px;
        }

        @media screen and (max-width:767px) {
            
            flex-wrap: wrap;
            justify-content: center;
            display: flex !important;

            > div {
                width: 100%;
                margin: 10px auto;
                display: flex;
                justify-content: center;
                align-items: center;
            }

            > div + div{
                margin-left: auto;
            }

        }

        .best-card{
            display: flex;
            flex-direction: column;
        }

        .list-card{
            display: flex;
            flex-direction: column;
            overflow: hidden;
            

            .list{
                display: flex;
                overflow: hidden;
                overflow-x: auto;
                flex-direction: row;
                flex-wrap: unset;
                

                > div + div{
                    margin-left: 30px;
                }

                @media screen and (max-width:767px) {
                    overflow-x: hidden;
                    overflow-y: auto;
                    flex-wrap: wrap;
                    max-height: 260px;
                    box-sizing: border-box;
                    justify-content: center;
    
                    > div + div{
                        margin: 10px 0;
                    }
                }
            }

        }
    }

    .area-game{
        box-sizing: border-box;
        padding: 10px;
        margin: 10px auto;
        width: 100%;
        justify-content: space-between;
        flex-direction: row;
        display: flex;
        flex-wrap: wrap;

        &:after{
            content: '';
            width: 100%;
            height: 50px;
            display: block;
        }
        

        > div{
            background-color: rgb(72, 11, 129);
            border-radius: 8px;
            display: flex;
            width: 100%;
            margin: 0 5px;
            box-sizing: border-box;
            padding: 10px;
        }

       .area-game-play{
           display: flex;
           flex-direction: column;
           justify-content: center;

           path{
               fill: rgb(255, 217, 0);
           }
           
            header.data-current-game{
                display: flex;
                flex-direction: column;
                width: 100%;


                > div{
                    width: 100%;
                    margin: 0px auto;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    flex-wrap: wrap;
    
                    @media screen and (max-width:767px) {
                        width: 90%;
                    }
    
                    > * {
                        display: inline-flex;
                        margin: 5px 0;
                    }
                }
            }
       }

    }

    .server-balls{
        position: fixed;
        left: 0;
        bottom: 0;
        width: 100%;
        height: 50px;
        min-height: 50px;
        background-color: rgba(255, 255, 255, 0.513);
        display: flex;
        flex-direction: row;
        align-items: center;
        box-sizing: border-box;

        > span{
            display: inline-flex;
            width: 40px;
            min-width: 40px;
            height: 40px;
            min-height: 40px;
            align-items: center;
            justify-content: center;
            background-color: #F00;
            border-radius: 50%;
            box-sizing: border-box;
            transition: all ease 0.5s;
            transform: rotate(-45deg);
        }
    }
}